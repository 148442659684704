<template>
  <v-container fluid>
    <head-title
      text="Control de Diésel"
      :to="{ name: 'fleet-home' }"
    ></head-title>
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" v-if="searchType === 1">
        <date-field label="Fecha" v-model="date"></date-field>
      </v-col>
      <v-col cols="12" md="4" v-else>
        <date-field label="Mes" v-model="date" type="month"></date-field>
      </v-col>
      <v-col cols="12" md="1" class="text-end">
        <v-btn class="mx-1" fab dark small color="indigo" @click="search()">
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" class="text-right">
        <div class="text-center">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="indigo"
            :to="{ name: 'dispatch-diesel-create' }"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="indigo"
            @click="summary(false)"
          >
            <v-icon dark>mdi-file-pdf-box</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="indigo"
            @click="summary(true)"
          >
            <v-icon dark>mdi-file-excel</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      class="elevation-1"
      hide-default-footer
      disable-sort
      disable-pagination
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.created | datef }}
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ item.created | time }} h
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>
      <template v-slot:[`item.horometer`]="{ item }">
        {{ item.horometer | currency }}
      </template>
    </v-data-table>
    <new-register
      :dialog="dialog"
      @close="dialog = false"
      @update="search()"
    ></new-register>
    <summary-diesel
      v-if="isX != undefined"
      :dialog="report"
      :isX="isX"
      @close="(report = false), (isX = undefined)"
    ></summary-diesel>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    DateField: () => import("@/components/fields/DateField"),
    NewRegister: () => import("@/components/fleets/Create"),
    SummaryDiesel: () => import("@/components/fleets/SummaryDiesel"),
  },
  data: () => ({
    loading: false,
    dialog: false,
    searchType: "",
    date: "",
    report: false,
    isX: undefined,
    headers: [
      { text: "Fecha", value: "date" },
      { text: "Hora", value: "time" },
      { text: "Vehículo", value: "vehicle_id" },
      { text: "Piloto", value: "driver" },
      { text: "Creado", value: "created_by" },
      { text: "Contador", value: "horometer", align: "right" },
      { text: "Cantidad", value: "quantity", align: "right" },
    ],
    items: [],
  }),
  mounted() {
    this.searchType = 1;
    this.date = new Date().toISOString().substr(0, 10);
    this.search();
  },
  methods: {
    search() {
      var uri = "";
      if (this.date != undefined && this.date.length > 0) {
        if (this.searchType === 1) {
          uri = "api/fleet/?date=" + this.date;
        } else if (this.searchType === 0) {
          uri =
            "api/fleet/?year=" +
            this.date.substring(0, 4) +
            "&month=" +
            this.date.substring(5, 7);
        }
        this.getData(uri);
      } else {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
      }
    },
    getData(uri) {
      this.loading = true;
      requests.get(uri).then((res) => {
        if (res.status == 200) {
          this.items = res.data.results;
        }
      });
      this.loading = false;
    },
    summary(isX) {
      this.report = true;
      this.isX = isX;
    },
    create() {
      this.dialog = true;
    },
  },
  watch: {
    date: function () {
      this.search();
    },
  },
};
</script>