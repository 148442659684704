var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('head-title',{attrs:{"text":"Control de Diésel","to":{ name: 'fleet-home' }}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":[
          { text: 'Por Mes', value: 0 },
          { text: 'Por Fecha', value: 1 } ],"label":"Mostrar Datos"},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1),(_vm.searchType === 1)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('date-field',{attrs:{"label":"Fecha"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('date-field',{attrs:{"label":"Mes","type":"month"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.search()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo","to":{ name: 'dispatch-diesel-create' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.summary(false)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-pdf-box")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.summary(true)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")])],1)],1)])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datef")(item.created))+" ")]}},{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("time")(item.created))+" h ")]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created))+" ")]}},{key:"item.horometer",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.horometer))+" ")]}}],null,true)}),_c('new-register',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"update":function($event){return _vm.search()}}}),(_vm.isX != undefined)?_c('summary-diesel',{attrs:{"dialog":_vm.report,"isX":_vm.isX},on:{"close":function($event){(_vm.report = false), (_vm.isX = undefined)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }